/** @format */

$primary: #001b48;
$primaryAlpha: #464b5d99;
$secondary: #00e0cd;
$primary-gradient: linear-gradient(158deg, #ffffffff 0%, #ffffffff 27%, #ffffffff 100%);
$system: #fdfdfd;
$systemBG: white;
$textMain: #464b5d;
$textLight: white;
$systemBG-light: #00000000;

$headerColor: $primary;
$headerColorSubmenu: $primary;
$headerColorHover: $textLight;

#mainnavbar {
  nav {
    background-color: $primary;
    padding: 0px 0px !important;
    color: $textLight;

    &#main {
      &:before {
        background-image: url('../images/map_light.png');
        opacity: 0.15;
        background-size: 50vw;
        position: absolute;
        left: 0px;
        bottom: 0px;
        top: 0px;
        right: 0px;
        content: '';
        color: $textLight;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        z-index: -1;
      }
    }
  }
}
// TOP BAR LOGO && LOADER LOGO
#logo {
  display: inline-block;
  position: relative;
  img {
    opacity: 0;
  }
  &:before {
    background-image: url('../images/logo_studio.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    content: '';
    color: $headerColor;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }
}
.loaderLogoContainer {
  display: inline-block;
  position: relative;
  img {
    opacity: 0;
  }
  &:before {
    background-image: url('../images/logo_studio_white.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    content: '';
    color: $textLight;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }
}

// LOADER
#logoSvg {
  circle:nth-child(1) {
    stroke: #ffffff00;
  }
  circle:nth-child(2) {
    stroke: #ffffff00;
  }
  circle:nth-child(3) {
    stroke: #ffffff00;
  }
  circle:nth-child(4) {
    stroke: #ffffff00;
  }
}

.loaderLogoContainer {
  &:before {
    opacity: 0;
    -webkit-animation-name: loaderOpacityFrame;
    animation-name: loaderOpacityFrame;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
// END LOADER

// LOGIN //
#login,
#consent-overflow {
  #logo {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-image: url('../images/logo_studio.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -40% !important;
    width: 200px !important;
    height: 200px !important;
    padding-left: 200px; /* Equal to width of new image */
  }
}
// END LOGIN //

// FONTS //
@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;700&display=swap');

$corefont: 'Baloo Paaji 2';

.titleFont {
  font-family: 'Baloo Paaji 2';
  text-transform: uppercase;
  font-size: 1.2em;
  color: $primary;
}

// STYLING FIX //
#close-item {
  // Close icon of items details
  color: white !important;
}

.card {
  .btn-secondary {
    // save icon of items details
    color: white !important;
  }
}
.card-header {
  // item details title container
  background-color: $primary !important;
  color: white;

  &.bg-transparent {
    background-color: transparent !important;
    color: $primary !important;
  }
  h3 {
    letter-spacing: 1px;
    font-size: 1em;
  }
}
