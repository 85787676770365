
.roomTooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-top: 20px;
  right: 20%;
  margin-right: -50px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.roomTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.roomTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.detailedMemberView {
  color: #001b48;
}

.atl-notification {
  max-height: 70vh;
  overflow-y: auto;
}
