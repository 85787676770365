.loaderContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  background-color: $primaryAlpha;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.loaderTimer {
  color: #fff;
  text-align: center;
  font-size: 2em;
  position: absolute;
  bottom: 10%;
}
.countdownContainer {
  background-color: $primaryAlpha;
  width: 300px;
  min-height: 100px;
  z-index: 100;
  left: 150;
}
.countdownContainer .counter {
  color: #fff;
  text-align: center;
}
.countdownContainer .counter span {
  color: #fff;
  font-size: 2em;

  text-align: center;
}
.countdownContainer .title {
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}
.countdownContainer .message {
  color: #fff;
  font-size: 0.8em;
  text-align: center;
}
.maintenanceLoaderContainer {
  background-color: #001b48ad !important;
}
.maintenanceData {
  position: absolute;
  top: 10%;
  color: #001b48;
  font-weight: 700;
  text-align: center;
  background: white;
  padding: 10;
  border-color: #001b48;
}
.loaderLogo {
  //opacity: 0;
  //-webkit-animation-name: loaderOpacityFrame;
  //animation-name: loaderOpacityFrame;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@keyframes loaderOpacityFrame {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loader {
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
  position: absolute;
}

.loaderContainer circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
  animation-name: preloader;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
  transform-origin: 170px 170px;
  will-change: transform;
}
.loaderContainer circle:nth-of-type(1) {
  stroke-dasharray: 550;
}
.loaderContainer circle:nth-of-type(2) {
  stroke-dasharray: 500;
}
.loaderContainer circle:nth-of-type(3) {
  stroke-dasharray: 450;
}
.loaderContainer circle:nth-of-type(4) {
  stroke-dasharray: 300;
}
.loaderContainer circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
.loaderContainer circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.loaderContainer circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  -moz-animation-delay: -0.45s;
  animation-delay: -0.45s;
}
.loaderContainer circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
